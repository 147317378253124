import React from 'react';

import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import AppContext from '../components/AppContext';
import EmergencyService from '../components/Layout/EmergencyService';
import Map from '../components/Map/Map';
import RevertPadding from '../components/RevertPadding';

export const frontmatter = {
  title: 'Notdienst',
  menu: 7,
};

export default () => (
  <Layout>
    <Helmet>
      <title>Notdienst</title>
    </Helmet>
    <h1>Apothekennotdienst</h1>
    <p>
      Rund um die Uhr gewährleisten die Korbacher Apotheken die Versorgung mit
      Medikamenten und Pharmazeutika. In dringenden Fällen können Sie also auch
      außerhalb der regulären Öffnungszeiten immer eine Notdienst-Apotheke
      auffinden.
    </p>
    <p>
      Auf dieser Seite finden Sie immer den aktuellen Notdienst. Tipp: Speichern
      Sie sich diese Seite als Lesezeichen um notfalls die richtige Apotheke
      immer schnell bereit zu haben.
    </p>
    {/* <p>
      Außerdem können Sie hier unseren Notdienstkalender für das ganze Jahr
      herunterladen. Die gedruckte Version im praktischen Taschenformat gibt es
      bei uns vor Ort zum Mitnehmen.
    </p> */}
    <h2 id="aktuell">Aktueller Apotheken-Notdienst in Korbach und Umgebung</h2>
    <AppContext.Consumer>
      {(context) => {
        if (!context || !context.emergencyServices) {
          return <p>Lädt...</p>;
        }
        const markers = context.emergencyServices.current.map((es) => ({
          lat: parseFloat(es.pharmacy.lat),
          long: parseFloat(es.pharmacy.lon),
          popup: (
            <>
              <h6>{es.pharmacy.name}</h6>
              <div>
                {es.pharmacy.street}
                <br />
                {es.pharmacy.zipCode} {es.pharmacy.location}
              </div>
              <p>Tel. {es.pharmacy.phone}</p>
            </>
          ),
          tooltip: es.pharmacy.name,
        }));
        return (
          <>
            {context.emergencyServices.current.map((es) => (
              <EmergencyService service={es} key={es.pharmacy.name} />
            ))}
            <RevertPadding>
              <Map
                position={[51.2732954, 8.8713331]}
                markers={markers}
                zoom={17}
              />
            </RevertPadding>
            <h3>Kommender Apotheken-Notdienst</h3>
            {context.emergencyServices.upcoming.map((es) => (
              <EmergencyService service={es} key={es.pharmacy.name} upcoming />
            ))}
          </>
        );
      }}
    </AppContext.Consumer>
  </Layout>
);
